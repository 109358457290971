<template>
  <div>
    <b-container class="vh-100 wrapper">
      <b-row class="border border-dark rounded navWrapper">
        <AppLayoutNav />
      </b-row>
      <b-row class="border border-dark rounded rowStyle">
        <slot />
      </b-row>
    </b-container>
    
  </div>
</template>

<script>
import AppLayoutNav from '@/layouts/AppLayoutNav'
export default {
  name: "AppLayoutGraph",
    components: {
    AppLayoutNav
  }
}
</script>

<style>
.wrapper {
  padding: 15px 25px 5px 25px;
  overflow: hidden;
}
.navWrapper {
  background-color: #8c8c8c;
  padding-bottom: 3px;
}
.rowStyle {
  height: 90%;
  background-color: #8c8c8c;
  justify-content: center;
}

</style>